$wrapper: 1300px;
$spacing: 8px;

.mainPage {
  display: block;
  width: 100%;
  min-height: 100vh;

  .mainPage-header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    &.withScroll {
      background: rgb(248, 89, 89);
      background: linear-gradient(
        90deg,
        rgba(65, 178, 255, 0.7) 0%,
        rgba(69, 67, 91, 0.7) 50%,
        rgba(248, 89, 89, 0.7) 100%
      );
    }
    .mainPage-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: $wrapper;
      margin: 0 auto;
      padding: $spacing * 2 $spacing * 2;
    }
  }

  .sectionNavigation {
    z-index: 10;
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    .sectionNavigation-item {
      display: block;
      border: 2px solid #333;
      border-radius: 10px;
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &.activeBlock {
        background: #333;
      }
      span {
        visibility: hidden;
      }
    }
  }

  // .btn-registration {
  //   border: 8px solid white;
  //   border-radius: 60px;
  //   font-size: 24px;
  //   font-weight: bold;
  //   padding: 10px 24px;
  //   border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  //   color: #fff;
  // }

  .mainPage-section {
    display: block;
    height: 100vh;
    width: 100%;
  }

  .mainPage-section1 {
    position: relative;
    .mainPage-section1-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-size: cover;
      background: url("./assets/frame_5_2_jpg.png") no-repeat center center;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(248, 89, 89);
        background: linear-gradient(0deg, rgba(248, 89, 89, 1) 0%, rgba(69, 67, 91, 1) 50%, rgba(65, 178, 255, 1) 100%);
        opacity: 0.8;
      }
      .videoBgSection1 {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: relative;
        top: 0;
        left: 0;
      }
    }
    .mainPage-section1-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      max-width: $wrapper;
      margin: 0 auto;
      padding: 0 $spacing * 2;
    }
    .mainPage-section1-title {
      display: block;
      position: relative;
      font-family: "Syncopate", sans-serif;
      font-size: 48px;
      font-weight: bold;
      color: #fff;
      margin-bottom: $spacing * 2;
      &::before {
        content: "";
        position: absolute;
        // top: 0;
        left: 0;
        right: 0;
        height: 14px;
        z-index: -1;
        border-radius: 7px;
        bottom: 5px;
        background: rgb(2, 0, 36);
        background: linear-gradient(
          90deg,
          rgba(2, 0, 36, 0) 0%,
          rgba(128, 198, 255, 1) 35%,
          rgba(94, 183, 255, 1) 70%,
          rgba(252, 153, 153, 1) 100%
        );
        opacity: 0.8;
      }
    }
    .mainPage-section1-subTitle {
      display: block;
      width: 100%;
      max-width: 400px;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 30px;
      font-weight: bold;
      color: #fff;
      margin-bottom: $spacing * 6;
    }
    .benefitsList {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: $spacing * 4;
      .benefitsList-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 $spacing * 5;
        width: 100%;
        max-width: 150px;
        @media screen and (max-width: 780px) {
          max-width: 130px;
        }
        @media screen and (max-width: 730px) {
          max-width: 90px;
        }
        .benefitsList-item-img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: $spacing * 2;
        }
        .benefitsList-item-text {
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  .mainPage-section2 {
    position: relative;
    .mainPage-section2-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-size: cover;
      background: url("./assets/bg2.png") no-repeat center center;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(35, 35, 35);
        background: linear-gradient(90deg, rgba(35, 35, 35, 0.35057773109243695) 0%, rgba(35, 35, 35, 1) 100%);
        opacity: 0.8;
      }
      .videoBgSection2 {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
      }
    }
    .mainPage-section2-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      max-width: $wrapper;
      margin: 0 auto;
      padding: 0 $spacing * 2;
    }

    .mainPage-section2-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: auto;
      .mainPage-section2-box-title {
        display: block;
        width: 100%;
        max-width: 665px;
        text-align: left;
        line-height: 60px;
        font-family: "Roboto", sans-serif;
        font-size: 45px;
        font-weight: bold;
        color: #fff;
        margin-bottom: $spacing * 4;
      }
      .mainPage-section2-box-list {
        max-width: 400px;
        margin-bottom: 150px;
        .mainPage-section2-box-list-item {
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: $spacing * 4;
          &:last-child {
            margin-bottom: 0;
          }
          .mainPage-section2-box-list-item-img {
            display: block;
            width: 60px;
            height: 50px;
            object-fit: scale-down;
            margin-right: $spacing * 2;
          }
          .mainPage-section2-box-list-item-text {
            display: block;
            width: 100%;
            font-weight: bold;
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            color: #fff;
          }
        }
      }
    }

    .mainPage-section2-box2 {
      display: flex;
      align-items: center;
      flex-direction: column;
      .mainPage-section2-box2-title {
        display: block;
        width: 100%;
        max-width: 565px;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        margin-bottom: $spacing * 2;
      }
      .mainPage-section2-box2-useCounter {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: $spacing * 2 $spacing * 4;
        font-size: 24px;
        border-radius: 50px;
        background: linear-gradient(to right, #fa7575, #80c6ff);
        color: #333;
        width: 100%;
        max-width: 300px;
        z-index: 0;
        &:after {
          content: "";
          position: absolute;
          top: $spacing * 0.75;
          left: $spacing * 0.75;
          right: $spacing * 0.75;
          bottom: $spacing * 0.75;
          border: 0 solid transparent;
          border-radius: inherit;
          background: #fff;
          z-index: -1;
        }
      }
    }
  }

  .mainPage-section3 {
    .mainPage-section3-container {
      display: flex;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      .mainPage-section3-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: url("./assets/bg3.1.png") no-repeat center center;
        background-size: cover;
        width: 100%;
        .mainPage-section3-left-title {
          display: block;
          width: 100%;
          max-width: 500px;
          text-align: left;
          line-height: 1.6em;
          font-family: "Roboto", sans-serif;
          font-size: 36px;
          font-weight: bold;
          color: #fff;
          margin-bottom: $spacing * 6;
        }
        .mainPage-section3-left-list {
          display: flex;
          flex-direction: column;
          max-width: 500px;
          .mainPage-section3-left-list-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 32px;
            &:last-child {
              margin-bottom: 0;
            }
            .mainPage-section3-left-list-item-img {
              display: block;
              width: 60px;
              height: 50px;
              object-fit: scale-down;
              margin-right: $spacing * 2;
            }
            .mainPage-section3-left-list-item-text {
              display: block;
              width: 100%;
              color: #fff;
              font-weight: bold;
              font-family: "Roboto", sans-serif;
              font-size: 18px;
            }
          }
        }
      }
      .mainPage-section3-right {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        .mainPage-section3-right-bg {
          position: absolute;
          background: url("./assets/большая челюсть 3.2.png") no-repeat center center;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background: rgb(212, 212, 212);
            background: linear-gradient(0deg, rgba(212, 212, 212, 1) 0%, rgba(255, 255, 255, 1) 28%);
            background-size: cover;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .mainPage-section4 {
    .mainPage-section4-container {
      display: flex;
      height: 100%;
      width: 100%;
      margin: 0 auto;

      .mainPage-section4-left {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        .mainPage-section4-left-bg {
          position: absolute;
          background: url("./assets/tablet-section4-rightBox_png.png") no-repeat center center;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background: rgb(212, 212, 212);
            background: linear-gradient(0deg, rgba(212, 212, 212, 1) 0%, rgba(255, 255, 255, 1) 28%);
            background-size: cover;
            opacity: 0.8;
          }
        }
      }
      .mainPage-section4-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: url("./assets/Block4_bg_rightBox.png") no-repeat center center;
        background-size: cover;
        width: 100%;
        .mainPage-section4-right-title {
          display: block;
          width: 100%;
          max-width: 500px;
          text-align: left;
          line-height: 1.6em;
          font-family: "Roboto", sans-serif;
          font-size: 36px;
          font-weight: bold;
          color: #fff;
          margin-bottom: $spacing * 6;
        }
        .mainPage-section4-right-list {
          display: flex;
          flex-direction: column;
          max-width: 500px;
          .mainPage-section4-right-list-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 32px;
            &:last-child {
              margin-bottom: 0;
            }
            .mainPage-section4-right-list-item-img {
              display: block;
              width: 60px;
              height: 50px;
              object-fit: scale-down;
              margin-right: $spacing * 2;
            }
            .mainPage-section4-right-list-item-text {
              display: block;
              width: 100%;
              color: #fff;
              font-weight: bold;
              font-family: "Roboto", sans-serif;
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .mainPage-section5 {
    .mainPage-section5-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: rgb(212, 212, 212);
      background: linear-gradient(
        180deg,
        rgba(212, 212, 212, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(210, 210, 210, 1) 100%
      );
      .mainPage-section5-content {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        max-width: 1400px;
        .mainPage-section5-content-wrapper {
          display: flex;
          position: relative;
          width: 100%;
          max-width: inherit;
          overflow-x: hidden;
          .mainPage-section5-content-wrapper-slide {
            display: flex;
            transform: translateX(0%);
            transition: transform 0.3s;
            .mainPage-section5-content-wrapper-slide-part {
              position: relative;
              flex-basis: 100%;
              padding: 5px 15px;
              margin-top: 80px;

              .mainPage-section5-content-wrapper-slide-part-img {
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
        .mainPage-section5-content-slide-controls {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          padding: 12px 24px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(22, 22, 22, 0.1);
          background: rgba(255, 255, 255, 0.3);
          transition: box-shadow 0.3s;
          cursor: pointer;
          &.back {
            left: -80px;
          }
          &.forward {
            right: -80px;
          }
          &:hover {
            box-shadow: 0 0 10px rgba(22, 22, 22, 0.3);
            transition: box-shadow 0.6s;
          }
          &:active {
            box-shadow: inset 0 0 3px rgba(22, 22, 22, 0.3);
          }
        }
      }
    }
  }

  .mainPage-section6 {
    position: relative;
    .mainPage-section6-bg {
      background: url("./assets/BG_Block-6.png") no-repeat center right;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    .mainPage-section6-container {
      display: flex;
      justify-content: center;

      flex-direction: column;
      width: 100%;
      height: 100%;
      max-width: $wrapper;
      margin: 0 auto;
      padding: 0 16px;

      .mainPage-section6-box-title {
        display: block;
        position: relative;
        font-family: "Syncopate", sans-serif;
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        margin-bottom: $spacing * 2;
      }
      .mainPage-section6-box-subtitle {
        display: block;
        width: 100%;
        max-width: 850px;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
      .mainPage-section6-containerIMG {
        display: block;
      }
      .mainPage-section6-container-subtitleList {
        display: block;
        width: 100%;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        color: #fff;
        margin-bottom: 30px;
      }
      .mainPage-section6-box-list {
        display: flex;
        flex-direction: column;

        .mainPage-section6-box-list-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin: 0 $spacing * 5;
          margin-bottom: 10px;
          width: 100%;
          max-width: 550px;
          @media screen and (max-width: 780px) {
            max-width: 130px;
          }
          @media screen and (max-width: 730px) {
            max-width: 90px;
          }

          &::before {
            content: "";
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50px;
            background: white;
            left: 0px;
            right: 0px;
          }
          .mainPage-section6-box-list-item-text {
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            margin-left: 30px;
          }
        }
      }
    }
  }

  .mainPage-section7 {
    .mainPage-section7-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: rgb(212, 212, 212);
      background: linear-gradient(
        180deg,
        rgba(212, 212, 212, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(210, 210, 210, 1) 100%
      );

      .mainPage-section7-container-leftBox {
        .mainPage-section7-container-leftBox-title {
          display: block;
          font-family: "Roboto", sans-serif;
          font-size: 36px;
          font-weight: bold;
          color: #444444;
          margin-bottom: $spacing * 5;
        }
        .mainPage-section7-container-leftBox-text {
          text-align: left;
          font-family: "Roboto", sans-serif;
          font-size: 21px;
          color: #7a7a7a;
          font-weight: bold;
          max-width: 510px;
          margin-bottom: $spacing * 5;
        }
        .mainPage-section7-container-leftBox-img2 {
          margin-left: 20px;
        }
      }
      .mainPage-section7-container-rightBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 150px;
        margin-top: 150px;
        .mainPage-section7-container-rightBox-link {
          margin-bottom: $spacing * 3;
        }
        .mainPage-section7-container-rightBox-boxText {
          display: flex;
          flex-direction: column;
          margin-top: 80px;

          .mainPage-section7-container-rightBox-boxText-text {
            text-align: center;
            font-family: "Roboto", sans-serif;
            font-size: 21px;
            color: #7a7a7a;
            max-width: 220px;

            &:not(:last-child) {
              margin-bottom: $spacing * 2;
            }
          }
        }
      }
    }
  }
}
